<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
            <Footer slot="footer"/>
       
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">
                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
                <template v-if="isFetching">
                    <div class="empty-cart" v-if="cartItemLength < 1 || cartItemLength == undefined ">
                        <h5 class="pb-4" style="opacity:0.4">
                            {{tran.empty_cart}}
                        </h5>
                        <div>
                            <button class="theme-btn-2 mt-20" style="min-height:70px; min-width:200px" @click.prevent="sendToMarketPlace">
                                     {{tran.buy_products}}
                          </button>
                        </div>
                    </div>
                </template>

                <!-- Mobile view -->
                <template v-if="windowWidth < 701 && cartItemLength > 0">
                    <div class="mt-4" style="padding-bottom:60px">

                        <div class="mobile-sum-div-port">
                            <div class="first-child mr-2">
                                <img src="../../assets/images/cartbabe.png"  height="140">
                        </div>
                                <div>
                                    <p class="pb-2">{{tran.cost_of_item_in_cart}}</p>
                                    <p><b><span>&#8358;</span>{{formatAmount(cartTotalAmount)}}</b></p>
                                    <hr>
                                    <p class="pb-2 pt-2">{{tran.number_of_units_cart}}</p>
                                    <p><b>{{cartTotalUnits}}</b></p>
                                    <hr>
                                    <p class="pb-2 pt-2">          Total Balance (wallet & bonus):</p>
                                    <p><b><span>&#8358;</span>{{formatAmount(userTotalBalance)}}</b></p>
                                </div>
                            </div>

                            <h6 class="pt-3 pb-1 text-center">{{tran.all_items_in_cart}}</h6>

                            <transition-group name="custom-classes-transition" enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutUp" :duration="{ enter: 1000, leave: 1000 }" tag="div">
                                <div class="card card-mobile mb-3 mt-2" v-for="(prod, index) in filterCart" :key="prod.product_id+prod.market">
                                    <div class="row no-gutters">
                                        <div class="col-md-2 col-2" style="max-width: 15.666667%; background-size:cover" :style="{ backgroundImage: 'url(' +  serVerImage+prod.product_image + ')' }">

                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="cartItemSelectedId == prod.product_id && loader">
                                         </span>
                                        </div>

                                        <div class="col-md-5 col-5">
                                            <div class="card-body" style="padding: 0.25rem 0.95rem 0.25rem 0.45rem;">
                                                <h5 class="card-title">{{prod.name || prod.resell_name}}</h5>
                                                <p class="card-text" style="color:#4F4F4F">{{prod.units}} {{tran.units}} </p>
                                                <i class="fa fa-minus text-left" :class="{'fade-elem':prod.units < 2 || cartItemLoader && cartProd == prod.product_id}" @click.prevent="patchUnit(prod, $event, 'minus')"></i>
                                                <input type="text" class="cart-input text-center" :value="prod.units"  style="border: 1px solid transparent; width:50px;pointer-events:none;" @keyup.prevent="patchUnit(prod, $event, 'input')">

                                                <i class="fa fa-plus text-left" @click.prevent="patchUnit(prod, $event, 'add')" :class="{'fade-elem':cartItemLoader && cartProd == prod.product_id}"></i>
                                                <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true" v-if="cartUnitIdChanged == prod.product_id && cartItemLoader"></span>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-5 text-right animated">
                                            <div class="btn-group">
                                                <button type="button" data-toggle="dropdown" class="no-border-no-bg" aria-haspopup="true" aria-expanded="false">
                                            <!-- <i class="fa fa-ellipsis-h"></i> -->
                                             <img src="../../assets/images/dots.png"  height="25">
                                         </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <button class="dropdown-item" type="button" style="cursor:pointer !important" @click="removeProductFromCart(prod, index)"
                                            data-toggle="modal" :data-target="'#sellModal' + index"

                                            ><i class="fa fa-minus-circle" style="color:#4F4F4F"></i> {{tran.remove_item}}</button>
                                                </div>
                                            </div>
                                            <p>
                                                <span>
                                            {{tran.roi}} :
                                        </span>
                                                <span>
                                            {{ prod.roi  ||  prod.resell_roi }} % 
                                        </span>
                                            </p>

                                            <p>
                                                {{tran.cost}} : <span>&#8358;</span>{{formatAmount(prod.customerRoi)}}
                                            </p>
                                            <p>
                                                {{tran.total_return}} : <span>&#8358;</span>{{formatAmount(prod.totalReturn)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>

                            <div style="display: block;opacity: 1; z-index: 30;height: 55px;
                                     color: #fff !important; min-width:100%; position: fixed; left:0px;
                                      bottom: 0px; text-align:center;">
                                <button  @click.prevent="purchaseProduct" v-if=" userTotalBalance >= cartTotalAmount && this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null" class="theme-btn" :class="{'fade-elem':loader2}" style="min-width:100% !important;border-radius:0px;   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); min-height:60px">
                          <i class="fa fa-shopping-cart" v-if="!loader2"></i> &nbsp;
                        {{loader2 ? '' :  tran.purchase_product}}
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loader2"></span>
              </button>
                                <button class="theme-btn"  style="min-width:100% !important;border-radius:0px;   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); min-height:60px" @click="sendToWallet" v-if="userTotalBalance < cartTotalAmount && this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null">
                                <i class="fa fa-shopping-cart"></i> &nbsp;  &nbsp;
                                {{tran.fund_wallet_to_purchase}}
                                </button>
                                  <button class="theme-btn"  style="min-width:100% !important;border-radius:0px;   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); min-height:60px" @click="gotoBvn" v-if="this.userProfileInfo.bvn == '0' || this.userProfileInfo.bvn == null">
                                <i class="fa fa-shopping-cart"></i> &nbsp;  &nbsp;
                                {{'Submit bvn'}}
                                </button>
                            </div>
                        </div>
                </template>

                <!-- Desktop View -->
                <div class="table-responsive pt-4" v-if="cartItemLength > 0 && windowWidth > 701">

                    <div class="display:flex;width:100%; justify-content:flex-end">
                        <div style="border-left:1px solid #c4c4c4" class="mb-4 pl-3">
                            <p>
                                Total Balance (wallet & bonus):
                            </p>
                            <p style="font-size:24px">
                                <span>&#8358;</span> <b>{{formatAmount(userTotalBalance)}}</b>
                            </p>
                          
                        </div>
                    </div>

                    <table>
                        <thead class="table-head">
                            <th>{{tran.product}}</th>
                            <th>{{tran.number_of_units}}</th>
                            <!-- <th>{{tran.amount}}</th> -->
                            <th>{{tran.cost}}</th>
                            <th>{{tran.roi}}</th>
                            <th>{{tran.total_return}}</th>

                            <th v-if="windowWidth >= 1110">{{tran.provider}}</th>
                            <th>{{tran.action}}</th>
                        </thead>

                        <tbody slot="body" name="custom-classes-transition" enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutUp" :duration="{ enter: 1000, leave: 1000 }" is="transition-group">

                            <tr v-for="(prod, index) in filterCart" :key="prod.product_id+prod.market">

                                <td>{{ prod.name || prod.resell_name}}</td>
                                <td> <i class="fa fa-minus text-left" :class="{'fade-elem':prod.units < 2 || cartItemLoader && cartProd == prod.product_id}" @click.prevent="patchUnit(prod, $event, 'minus')"></i>
                                    <input type="text"  class="cart-input text-center" :value="prod.units" style="border: 1px solid transparent; pointer-events:none; width:50px" @keyup.prevent="patchUnit(prod, $event, 'input')">

                                    <i class="fa fa-plus text-left" @click.prevent="patchUnit(prod, $event, 'add')" :class="{'fade-elem':  cartItemLoader && cartProd == prod.product_id}"></i>
                                    <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true" v-if="cartUnitIdChanged == prod.product_id && cartItemLoader"></span>
                                </td>
                                <!-- <td>{{ prod.amount }}</td> -->
                                <td>
                                    <span>&#8358;</span>{{formatAmount(prod.customerRoi) }}
                                </td>
                                <td>
                                    {{ prod.roi  || prod.resell_roi }} %
                                </td>

                                <td>
                                    <span>&#8358;</span>{{ formatAmount(prod.totalReturn)}}
                                </td>
                                <td v-if="windowWidth >= 1110">
                                    {{ prod.atp}}
                                </td>

                                <td>
                                    <button class="remove-btn" data-toggle="modal" :data-target="'#sellModal' + index" @click="removeProductFromCart(prod, index)"> {{tran.remove}}
                                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="cartItemSelectedId == prod.product_id && loader">
                                         </span>

                                        </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Cart total Units table-->
                <table class="table text-left" v-if="cartItemLength > 0 && windowWidth > 701" style="overflow:hidden">
                    <thead class="cart-total-bg">
                        <tr style="">
                            <th scope="col">{{tran.total}}</th>
                            <th scope="col" v-if="cartTotalUnits > 1">{{cartTotalUnits}} {{tran.units}}</th>
                            <th scope="col" v-else>{{cartTotalUnits}} {{tran.unit}}</th>
                            <th scope="col"> <span>&#8358;</span>{{formatAmount(cartTotalAmount)}}</th>
                            <th scope="col"></th>
                            <th scope="col" v-if="windowWidth >= 1100"></th>

                            <th scope="col" class="theme-color" v-if=" userTotalBalance >= cartTotalAmount"> <i class="fa fa-circle"></i> {{tran.sufficient_bal}}</th>

                            <th scope="col" class="color-red" v-else><i class="fa fa-circle"></i>{{tran.insufficient_fund}}</th>

                            <th scope="col"  v-if=" userTotalBalance >= cartTotalAmount && this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null">
                                <button class="theme-btn" @click.stop="purchaseProduct" :class="{'fade-elem':loader2}">
                                     {{loader2 ? '' :  tran.purchase_product}}

                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loader2"></span>
                                    </button>
                            </th>

                            <th scope="col"  v-if=" userTotalBalance < cartTotalAmount && this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null">
                                <button class="theme-btn"   @click="sendToWallet">{{tran.fund_wallet_to_purchase}}</button>
                            </th>
                            <th scope="col" v-if="this.userProfileInfo.bvn == '0' || this.userProfileInfo.bvn == null">
                                <button class="theme-btn"   @click="gotoBvn">{{'Submit bvn'}}</button>
                            </th>
                        </tr>
                    </thead>
                </table>

                <!-- Remove product from cart Modal-->
                <div class="modal fade" :id="'sellModal' + indexFromTable" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header text-right">
                                <h4 class="modal-title" id="exampleModalLongTitle"></h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            <div class="modal-body ">
                                <h4 class="text-center mb-2">{{tran.remove_from_cart}}</h4>
                                <p class="text-center mb-4">{{tran.remove_from_cart_prompt}}</p>
                                <div class="display-div sell-item">

                                    <div class="prod-div">
                                        <p>{{tran.product}} :</p>
                                        <h5 class="pt-4"><b>{{productToDelete.name}} </b></h5>
                                        <h5> <b>{{productToDelete.atp}}</b> </h5>
                                    </div>

                                    <div class="unit-block-parent">
                                        <div class="unit-block">

                                            <p>{{tran.number_of_units}} :</p>
                                            <p><b>{{productToDelete.units}}</b></p>
                                        </div>

                                        <div>
                                            <p>{{tran.approx_ret}} : </p>
                                            <p v-if="productToDelete.totalReturn !== undefined"><b> <span>&#8358;</span>  {{formatAmount(productToDelete.totalReturn)  }}</b></p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="display-div mt-4">
                                <div class="cancel-cart" data-dismiss="modal" aria-label="Close">
                                    <p>{{tran.no_cancel}}</p>
                                </div>
                                <div class="remove-cart" @click="removeCartItem" data-dismiss="modal" aria-label="Close">

                                    <p>{{ tran.yes_remove}} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Fund wallet Modal -->
                <div class="modal fade trans-modal" id="fundWallet" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="static" v-if="showModal">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content" v-if="fundOptionModal">
                            <div class="modal-header">
                                <h4 class="modal-title head-font" id="exampleModalLongTitle">{{tran.fund_wallet}}</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                                               </button>
                            </div>
                            <div class="modal-body text-left">
                                <div class="card bg-light mb-3" style="max-width: 28rem;">
                                    <div style="min-height:100px" class="fund-input">
                                        <p>{{tran.amount_to_fund}} <span>&#8358;</span> :</p>
                                        <input type="text" :value="returnMinAmount" @keyup="getFundValue($event)" class="form-input text-center" autofocus>
                                    </div>

                                    <div>
                                            <p>{{tran.due_to_monify}}</p>

                                            <div style="background:#fafafa;padding:20px;color:#0094FF; text-align:Center" class="mt-2">
                                                <h3> <span>&#8358;</span><b>{{ returnMinAmountUserIsPaying}}</b></h3>

                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <template v-if="cartItemLength > 0">
                                                <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                    <input type="checkbox" class="custom-control-input" id="customControlInline1" v-model="sponsor" @click="checkForSponsor($event)">
                                                    <label class="custom-control-label" :title="tran.sponsor_items_message " v-tippy='{ arrow : true, theme:"light", placement:"bottom",arrowType : "round"}' for="customControlInline1"><b>{{tran.sponsor_items}}</b></label>

                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer text-center" @click="fundWalletNow" :class="{'fade-elem' : returnFundBtnStatus}">
                                    <span>{{ loader ? '' : tran.proceed_to_pay}} </span>

                                    <span class="loader" v-if="loader" style="top:92%"></span>
                                </div>

                            </div>
                            <div class="modal-content" v-if="offlineDetailModal">
                                <div class="modal-header text-right animated fadeIn">
                                    <h4 class="modal-title" id="exampleModalLongTitle"> </h4>

                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                       <span aria-hidden="true"> &times; </span>
                                                    </button>

                                </div>
                                <div class="modal-body ">
                                    <template v-if="confirmWalletData.userHasNotClickedConfirmPayment && !confirmWalletData.confirmingPayment">
                                        <div style="display:flex">
                                            <div style="flex-basis:25%" class="pl-2">

                                                <img src="../../assets/images/chev.png"  height="20" style="cursor:pointer" @click="showFundModalOption" :title="tran.go_back_to" v-tippy='{ arrow : true, arrowType : "round"}'>
                                                            </div>
                                                <div>
                                                    <h4 class="text-center head-font mb-4" style="display:inline">{{tran.bank_transfer}}</h4>
                                                </div>
                                            </div>

                                            <div class="withdraw-bal mt-40">
                                                <p>
                                                    <b>

                                                                <span style="font-size: 24px">Pay <span>&#8358;</span>{{(formatAmount(offlineMinAmount)) }}</span> <br />to your personalised account details below: 
                                                                </b>
                                                </p>

                                            </div>

                                            <div class="withdraw-input mt-2">
                                                <p class="text-center" style="color:#0094FF">Bank Name</p>
                                                <p class="text-center" style="font-size:17px"><b>{{offlinePaymentDetail.bank_name}}</b></p>
                                            </div>

                                            <div class="withdraw-input mt-2">
                                                <p class="text-center" style="color:#0094FF">Account Name</p>
                                                <p class="text-center " style="font-size:17px"><b>{{offlinePaymentDetail.account_name}}</b></p>
                                            </div>

                                            <div class="withdraw-input mt-2">
                                                <p class="text-center" style="color:#0094FF">Account Number</p>
                                                <p class="text-center " style="font-size:17px"><b>{{userAccountNumberSpaced}}</b></p>
                                            </div>

                                            <div class="note-div mt-2">
                                                <h6 style="color:#FDC23C"><b>Remember :</b></h6>

                                                <p class="mt-2">This is your bank account on the reQuid platfom. <span style="color:#4F4F4F"><b>You can fund your wallet anytime by doing a tranfer to this account.</b></span></p>
                                                <p class="pt-2">

                                                    <span style="color:#4F4F4F" ><b>You can even save it as a beneficiary on your bank app!</b></span>

                                                </p>
                                            </div>

                                            <p class="text-center pt-4 pb-4" @click="fundWalletOnline" data-dismiss="modal" aria-label="Close" style="cursor:pointer">Try alternative <span  style="color:#0094FF"> payment</span> method</p>
                                    </template>

                                    <template v-if="confirmWalletData.fundWalletUnsuccessful && !confirmWalletData.confirmingPayment">
                                        <div>
                                            <p class="pb-2"> <img src="../../assets/images/cautionicon.png"  height="25" class="close-info-div"> <span style="padding-left:15px; color:#CD1C44;"><b>Payment not successful</b></span></p>
                                            <p class="pb-2 animated fadeIn" style="padding-left:40px"><b>Transfer Details</b></p>

                                                                <div class="pb-2 animated fadeInUp" style="display:flex ; animation-delay: 1s; flex-direction:row">
                                                                           <p  style=" padding-left:40px;flex-basis:50%">Bank Name :</p>

                                                                        <p> <b> {{offlinePaymentDetail.bank_name}}</b></p>


                                                                </div>
                                                               
                                                                <div class="pb-2 animated fadeInUp" style="animation-delay:1.5s; display:flex; flex-direction:row">

                                                     <p style=" padding-left:40px;flex-basis:50%">Account Number : </p>
                                                         <p  style="animation-delay:1.5s;"><b> {{userAccountNumberSpaced}}</b></p>
                                                                </div>
                                                 <div class="pb-2 animated fadeInUp" style="display:flex; flex-direction:row ;animation-delay: 2s;">

                                                        <p style="padding-left:40px; flex-basis:50%">
                                                             Account Name : 
                                                                </p>

                                                                <p> <b>{{offlinePaymentDetail.account_name}}</b></p>
                                                 </div>


                                                 <div class="pb-2 animated fadeInUp" style="display:flex; flex-direction:row; animation-delay: 2.5s; "  >
                                                <p style="padding-left:40px;flex-basis:50%"><b>{{tran.fund_with}} :</b></p>

                                                 <p> <b> <span style="color:#0094FF"><span> &#8358;</span>{{(formatAmount(offlineMinAmount)) }} </span></b></p>

                                                 </div>
                        
                                        </div>
                                    </template>
                                    <template v-if="confirmWalletData.confirmingPayment">
                                        <div>
                                            <p class="pb-4 pt-4"> <img src="../../assets/images/redirect.png"  height="25" style="cursor:pointer" class="close-info-div fa-spin"> Confirming Payment </p>
                                        </div>
                                    </template>
                                    <template v-if="confirmWalletData.fundAndPurchase && !confirmWalletData.confirmingPayment">
                                        <div>
                                            <p class="pb-4 animated fadeInUp" style="animation-delay:1s "> <img src="../../assets/images/success.png"  height="25" style="cursor:pointer" class="close-info-div" > Payment successful</p>
                                                <p class="pb-4 animated fadeInUp" style="animation-delay: 2s"> <img src="../../assets/images/success.png"  height="25" style="cursor:pointer" class="close-info-div"> 
                                                    
                                                    Items purchased successfully 
                                                
                                                </p>
                                                    <p class="pb-4 animated fadeInUp" style="animation-delay: 3s"> <img src="../../assets/images/redirect.png"  height="25" style="cursor:pointer" class="close-info-div fa-spin"> 
                                                        
                                                        Redirecting to portfolio 
                                                    
                                                    </p>
                                        </div>
                                    </template>
                                    <template v-if="confirmWalletData.walletFunded && !confirmWalletData.confirmingPayment">
                                        <div>
                                            <p class="pb-2 pt-2 animated fadeInUp"> <img src="../../assets/images/success.png"  height="25" style="cursor:pointer" class="close-info-div"> Payment successful</p>
                                                <p class="pb-2 pt-2 animated fadeInUp" style="cursor:pointer; animation-delay:1s" v-if="cartItemLength > 0"> <img src="../../assets/images/cautionicon.png"  height="25"  class="close-info-div"> Items in cart not purchased</p>
                                        </div>
                                    </template>
                                </div>
                                <div class="modal-footer text-center" @click="checkIfWalletHasBeenFunded" :class="{'fade-elem' : returnCheckingWalletStatus}" v-if="confirmWalletData.confirmBalance && !confirmWalletData.confirmingPayment" style="position:relative">
                                    <span>{{ returnCheckingWalletStatus ? '' : tran.confirm_payment}} </span>

                                    <span class="loader" v-show="returnCheckingWalletStatus"></span>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
    </Wrapper>
          
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations";
import Footer from '../partials/Footer'
import {
    commonJs
} from './mixins/commonJs.js';
import http from "../../http/index";
import api from "../../apis/index";
import $ from 'jquery'
import {
    mapState
} from 'vuex';
export default {
    mixins: [commonJs],
    data() {

        return {
            tran: {},
            indexFromTable: null,
            currentPage: 1,
            cartItemSelectedId: null,
            totalPages: 0,
            fundAmount: 1,
            cardObject: '',
            userBalanceOncomponent: '',
            componentWalletBalance: '',
            minAmount: '',
            cartUnitIdChanged: null,
            cartItemLoader: false,
            cartCategory: null,
            sponsor: true,
            cartProd: '',
            showModal: true,
            fundWallet: [{
                    id: 1,
                    card: "Fund with Card"
                },
                {
                    id: 2,
                    branch: "Fund with bank transfer"
                },
            ],
            payType: null,
            productToDelete: {

                cost_per_unit: '',
                customerRoi: ''
            },

            fadeFundWalletBtn: false,
            checkingWalletStatus: false,
            confirmWalletData: {
                confirmBalance: true,
                confirmingPayment: false,
                fundAndPurchase: false,
                fundWalletUnsuccessful: false,
                walletFunded: false,
                userHasNotClickedConfirmPayment: true
            }

        }
    },

    methods: {

        sendToProductPage(prod) {
            this.$router.push({
                params: {
                    id: prod.product_id
                },
                path: `/market/${prod.market}/${prod.name
          .toLowerCase()
          .split(" ")
          .join("")}/${prod.product_id}`
            });
        },


        checkForSponsor(event) {

            if (event.target.checked) {

                this.sponsor = true
            } else {
                ga("send", "event", "business goals", "Sponsor item", "product unsponsored");
                this.sponsor = false
            }

        },

        addToUsers() {
            this.users.unshift(this.userData);
        },
  sendToWallet() {
      this.$router.push({ path: "/wallet", query: { openFundWallet: true, fundAmount:this.amount } });
    },
        removeCartItem() {
            this.$store.dispatch('market/deleteCartItem', this.productToDelete)
        },

        getFundValue(e) {
            let fundValue = e.target.value;
            e.target.value = parseInt(
                fundValue.replace(/\D/g, ""),
                10
            ).toLocaleString();

            this.fundAmount = e.target.value
            this.minAmount = e.target.value
            let inputValue = this.fundAmount.toString().replace(/,/g, "");
            if (isNaN(this.minAmount.toString().replace(/,/g, ""))) {

                this.minAmount = ''

            }

            this.$helpers.log(inputValue, 'value of input')
            if (inputValue >= 1) {
                this.fadeFundWalletBtn = false;
            } else {
                this.fadeFundWalletBtn = true;
            }
        },

        fundWalletWithMinimumAmount() {

            if (this.userProfileInfo.balance < this.cartTotalAmount && this.cartItemLength > 0) {
                this.minAmount = (this.cartTotalAmount - this.userBal).toFixed(2)
            } else {
                this.minAmount = 5000
            }
        },
        patchUnit(prod, event, actionType) {
            this.cartProd = prod.product_id
            this.cartItemLoader = true;
            this.cartUnitIdChanged = prod.product_id

            // this.cartCategory = prod.category

            let inputValue = parseInt(
                event.target.parentNode.querySelector(".cart-input").value,
                10
            );

            if (actionType !== 'input') {

                if (actionType == 'add') {
                    inputValue = isNaN(inputValue) ? 0 : inputValue;
                    inputValue++;
                }

                if (actionType == 'minus') {
                    inputValue = isNaN(inputValue) ? 0 : inputValue;
                    inputValue--;
                }

            }

            event.target.parentNode.querySelector(".cart-input").value = inputValue;

            let apiObject = {
                product_id: prod.product_id,
                units: inputValue,
                market: prod.market
            }

            http.patch(api.addGetDeleteUpdateCart, apiObject)
                .then(response => {
                    this.cartItemLoader = false;
                    this.$helpers.log(response);

                    this.$store.dispatch('market/getCartItems')
                })

                .catch(error => {
                    if (error.response)
                        this.cartItemLoader = false;
                    this.$helpers.log(error.response);
                    this.$store.commit("domElements/openNotificationMessage")

                    this.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
                });
        },

        fundWalletNow() {
            let vm = this
            let amountData = {
                amount: this.minAmount.toString().replace(/,/g, ""),
                sponsor: this.sponsorStatus
            }

            if (amountData.amount > 1) {

                this.$store.dispatch('market/fundWalletOffline', amountData)
            }

            // let startInterval = setInterval(() => {

            //     http.get(api.getUserBalance)
            //         .then(response => {
            //             this.$helpers.log(response, "new account balance");

            //             if (vm.cartItemLength == null && response.data.data.balance !== vm.userProfileInfo.balance) {

            //                 vm.informUser(vm.tran.your_wallet_success);
            //                 clearInterval(startInterval)

            //                 vm.$store.dispatch('auth/getUserProfile')
            //                 vm.$store.dispatch('market/getCartStatus')
            //             } else if (vm.cartItemLength !== null) {

            //                 if (response.data.data.balance !== vm.userProfileInfo.balance && response.data.data.cart_item_count == 0) {
            //                     vm.informUser(vm.tran.your_wallet_success_items_bought);

            //                     vm.$router.push('/portfolio')
            //                     clearInterval(startInterval)
            //                     vm.$store.dispatch('auth/getUserProfile')
            //                     vm.$store.dispatch('market/getCartStatus')
            //                 } else if (response.data.data.balance !== vm.userProfileInfo.balance && response.data.data.cart_item_count > 0) {

            //                     vm.informUser(vm.tran.your_wallet_success);
            //                     clearInterval(startInterval)
            //                     vm.$store.dispatch('auth/getUserProfile')
            //                     vm.$store.dispatch('market/getCartStatus')
            //                 }
            //             }

            //         })
            //         .catch(error => {
            //             vm.$helpers.log(error.response);
            //             vm.$store.commit("domElements/openNotificationMessage")

            //             vm.$store.commit("domElements/saveNotificationMessage")
            //         });

            // }, 9000)
        },
               checkIfWalletHasBeenFunded() {

            let oldBalance = this.userProfileInfo.balance

            let cartLength = this.cartItemLength

            if(cartLength == undefined){

                cartLength = 0
            }
            this.checkingWalletStatus = true
            this.confirmWalletData.confirmingPayment = true
            let vm = this
            // let walletLength = thisWall
        
    vm.$helpers.log(vm.cartItemLength, 'cartItemLengjt')
            http.get("api/v1/account/payment/confirmation"+"?cart_item_count="+cartLength+"&"+"balance="+oldBalance)
                .then(response => {
                  
                    vm.$helpers.log(response, "new account balance");
                    vm.checkingWalletStatus = false


//if user pays successfully

if(response.data.status){
vm.$helpers.log(response, 'response in time')

                if(response.data.data.payment && response.data.data.cart == false ){
                        vm.$store.dispatch('market/getCartStatus')

                          vm.confirmWalletData = {
                             
                                fundAndPurchase: false,
                                fundWalletUnsuccessful: false,
                                walletFunded: true,
                                  confirmingPayment: false,
                                userHasNotClickedConfirmPayment: false,
                                confirmBalance:false
                            }


                        try{
                             gtag('event', 'conversion', {'send_to': 'AW-703278616/P1_5CLGz-bMBEJjcrM8C'});

                        }

                    catch{

                               }
                    }


                 //if user pays successfully and buys items in cart

            else if(response.data.data.payment && response.data.data.cart){
                                  this.$store.commit('market/emptyCartItems');
                            vm.confirmWalletData = {
                                confirmBalance: false,
                                confirmingPayment: false,
                                fundAndPurchase: true,
                                fundWalletUnsuccessful: false,
                                walletFunded: false,
                                userHasNotClickedConfirmPayment: false

                    }
                            setTimeout(() => {

                                vm.showModal = true
                                $('.modal-backdrop').removeClass("modal-backdrop")
                                vm.$router.push('/portfolio')
                            }, 8000)

                try {

                             fbq("track", "Purchase", { value: payload, currency: "NGN" });
                             gtag('event', 'conversion', {
                                'send_to': 'AW-703278616/P1_5CLGz-bMBEJjcrM8C'
                            });
                    
                        }

                catch {


                }

          }

        }

                    vm.getAccountBalance()
                })

                .catch(error => {
                    vm.$helpers.log(error.response);
                       vm.confirmWalletData.confirmingPayment = false
                    vm.$store.commit("domElements/openNotificationMessage")
                    vm.checkingWalletStatus = false
                    vm.$store.commit("domElements/saveNotificationMessage", error.response.data.message)
                        vm.confirmWalletData = {
                           confirmBalance : true,
                            fundAndPurchase: false,
                            fundWalletUnsuccessful: true,
                            walletFunded: false,
                              confirmingPayment: false,
                            userHasNotClickedConfirmPayment: false

                        }

                });

        },


        showFundModalOption() {
            this.$store.commit('market/showFundOptionModal');
            this.fundWalletWithMinimumAmount()
            this.confirmWalletData = {
                confirmingPayment: false,
                confirmBalance: true,
                fundAndPurchase: false,
                fundWalletUnsuccessful: false,
                walletFunded: false,
                userHasNotClickedConfirmPayment: true
            }
            ga("send", "event", "business goals", "fund wallet", this.minAmount);

        },

        fundWalletOnline() {

            let vm = this
            this.$store.commit("market/startLoader");
            let amountData = {
                amount: vm.minAmount.toString().replace(/,/g, ""),
            }

            http
                .post(api.payOnline, amountData)

                .then(response => {

                    vm.payWithPaystack(response.data.data);
                    vm.$store.commit("market/stopLoader");
                })

                .catch(error => {

                    vm.$store.commit("domElements/openNotificationMessage")

                    vm.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
                    vm.$store.commit("market/stopLoader");
                });
        },

        removeProductFromCart(prod, index) {
            this.cartItemSelectedId = prod.product_id
            this.indexFromTable = index
            this.productToDelete = prod

            let product = {
                product_id: prod.product_id,
                units: prod.units,
                market: prod.market,
            }

            this.$store.commit('market/saveRemoveCartItemObject', product)
        },

        purchaseProduct() {
            this.$store.dispatch('market/purchaseProduct', this.cartTotalAmount)
        },

        sendToMarketPlace() {
            this.$router.push('/market');
        },

        payWithPaystack(paymentDetail) {

            // let paymentDetail = this.cardObject;

            let vm = this;

            var handler = PaystackPop.setup({
                key: paymentDetail.provider_key,
                email: paymentDetail.email,
                amount: paymentDetail.amount_kobo,

                currency: "NGN",
                ref: paymentDetail.pay_ref,

                callback: function (response) {

                    vm.$store.dispatch('market/getAccountOverview')
                    vm.$store.dispatch('market/getCartItems')
                    let verify_pay = {
                        reference: response.reference
                    }

                    vm.verifyCardPayment(verify_pay)
                    vm.$forceUpdate()
                },
                onClose: function () {
                    // vm.$router.push('/admin/transaction');

                }
            });
            handler.openIframe();
        },
        informUser(message) {

            $('.modal-backdrop').removeClass("modal-backdrop")
            this.showModal = false
            this.$toast.success({
                title: this.tran.success,
                timeOut: 5000,
                icon: require("../../assets/images/successicon.png"),
                message: message,
                position: "top center"
            });
            this.getAccountBalance()
            setTimeout(() => {

                this.showModal = true
            }, 2000)
        },
        verifyCardPayment(payload) {
            let vm = this
            http
                .get(api.verifyPaymentByCard + '?reference=' + `${payload.reference}`)
                .then(response => {
                    this.$helpers.log(response, "card verified");
                    vm.getAccountBalance()
                })
                .catch(error => {
                    this.$helpers.log(error.response, "card unverified flag");
                    this.$store.commit("domElements/openNotificationMessage")

                    this.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
                });
        },

        getAccountBalance() {
            http.get(api.getUserProfile)
                .then(response => {
                    this.$helpers.log(response, "new account balance");
                    this.userBalanceOncomponent = response.data.data.balance;
                })
                .catch(error => {
                    this.$helpers.log(error.response);
                    if (error.response.data.message !== "unauthenticated") {
                        this.$store.commit("domElements/openNotificationMessage")

                        this.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
                    }
                });
        },

        getAccountOverviewFromComponent() {

            http.get(api.getAccount)

                .then(response => {
                    this.$helpers.log(response, "account overview from component");
                    this.userBalanceOncomponent = response.data.data.user.balance;
                })

                .catch(error => {
                    if (error.response) {
                        if (error.response.data.message !== "unauthenticated") {

                            this.$helpers.log(error.response);
                        }
                    }

                });
        },
    },
    watch: {
        cardObject: function () {
            this.payWithPaystack();
        },
    },
    computed: {
        ...mapState({
            cartItems: state => state.market.cartItems,
            removeCartItemObject: state => state.market.removeCartItemObject,
            offlineDetailModal: state => state.market.offlineDetailModal,
            fundOptionModal: state => state.market.fundOptionModal,
            offlinePaymentDetail: state => state.market.offlinePaymentDetail,
            loader2: state => state.market.loader2,
            accountOverview: state => state.market.accountOverview,
            userBal: state => state.market.userBalance,
            userTotalBalance: state => state.market.userTotalBalance,
            offlineMinAmount: state => state.market.offlineMinAmount,
        }),

        userAccountNumberSpaced() {
            return this.offlinePaymentDetail.account_number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ");
        },

        sponsorStatus() {
            return this.sponsor;
        },

        returnCheckingWalletStatus() {
            return this.checkingWalletStatus
        },

        filterCart() {
            return this.cartItems.filter((prod) => {
                return prod.name.toLowerCase().match(this.searchString.toLowerCase()) || prod.resell_name.toLowerCase().match(this.searchString.toLowerCase()) || prod.atp.toLowerCase().match(this.searchString.toLowerCase());
            })
        },

        returnComponentBalance() {
            return this.userBalanceOncomponent
        },

        returnMinAmount() {
            if (this.minAmount.toString().replace(/\,/g, '') % 1 !== 0) {

                return this.minAmount.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

            } else {

                return this.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            }
        },
        returnMinAmountUserIsPaying() {
            if (this.minAmount == '') {
                return ''
            }
            if (this.minAmount.toString().replace(/\,/g, '') % 1 !== 0) {

                return (parseFloat(this.minAmount.toString().replace(/\,/g, '')) + parseFloat(50)).toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

            } else {

                return (parseFloat(this.minAmount.toString().replace(/\,/g, '')) + parseFloat(50)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
            }
        },

        returnFundBtnStatus() {

            return this.fadeFundWalletBtn;
        }
    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper

    },

    mounted() {

        this.tran = trans;

        let heading = {

            main: this.tran.cart,
            sub: this.tran.all_items_in_cart
        }
        //this.$store.dispatch('market/getCartItems');

        this.$store.commit('market/savePageHeading', heading)
        this.$store.commit('market/stopIsFetching')
        this.$store.commit('auth/saveCurrentRoute', this.$route.path)
    },

    beforeMount() {
        this.getAccountOverviewFromComponent();
        this.$store.commit('market/checkSearchObject', 'cart')
    },

    // beforeCreate() {
    //     this.$store.dispatch('market/getAccountOverview')

    // },

    beforeDestroy() {

        this.$store.commit("market/startIsFetching");
        this.$store.commit("market/startPreloader");
    }
}
</script>

<style>

</style>
