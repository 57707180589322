<template>
<div>
    <cart />
</div>
</template>

<script>
import cart from '../components/dashboard/Cart.vue'
export default {
    components: {
        cart
    }
}
</script>

<style>
